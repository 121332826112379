import { dehydrate } from '@tanstack/react-query';
import { getQueryClient, isMobileRequest, redirect } from 'common-nextjs';
import { createSession } from 'fetcher-session';
import { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';
import { fetchLanderById, fetchLandersBySlug } from '~/api/landers';
import { fetchAllPageViewData, fetchPageView } from '~/api/tiles';
import Layout from '~/components/Layout';
import PageView from '~/components/legos/PageView';
import { PageViewDataContext } from '~/contexts/PageViewDataContext';
import { visitedNavigationalLander } from '~/services/analytics/events/generic';
import { CustomNextPage } from '~/typings/custom-next';
import { RailsLander } from '~/typings/services/rails/landers';
import { RailsPageView } from '~/typings/services/rails/tile';
import getLanderId from '~/utils/getLanderId';

interface Query extends ParsedUrlQuery {
  shop: string[];
}

interface Props {
  lander: RailsLander;
  pageView?: RailsPageView;
  pageViewData?: any;
}

const ShopFanGearLandingPage: CustomNextPage<Props> = ({
  lander,
  pageView,
  pageViewData,
}) => {
  useEffect(() => {
    visitedNavigationalLander(lander, pageView);
  }, [lander.id, pageView?.id]);

  return (
    <Layout title={lander.name} container={false}>
      <PageViewDataContext.Provider value={{ data: pageViewData, pageView }}>
        <PageView pageView={pageView} />
      </PageViewDataContext.Provider>
    </Layout>
  );
};

ShopFanGearLandingPage.getInitialProps = async ctx => {
  const query = ctx.query as Query;
  const queryClient = getQueryClient();

  // 'shop' because that's the base route in /pages
  const landerId = getLanderId(query.shop);

  let lander: RailsLander;

  if (landerId) {
    lander = await fetchLanderById.call(ctx, landerId);
  } else {
    const landers = await fetchLandersBySlug.call(ctx, query.shop);

    if (landers.length >= 1) {
      lander = landers[0];
    } else {
      throw await redirect(ctx, '/search', {
        statusCode: 302,
      });
    }
  }

  if (lander.type !== 'navigation') {
    throw await redirect(ctx, `/shop/[...lander]`, {
      as: `/shop/${lander.path}`,
      statusCode: 301,
    });
  }

  if (ctx.asPath?.split('?')[0] !== `/nav/${lander.path}`) {
    throw await redirect(ctx, `/nav/${lander.path}`);
  }

  try {
    const pageView = await fetchPageView.call(ctx, lander.page_view_id);
    const pageViewData = await fetchAllPageViewData(
      pageView,
      isMobileRequest(ctx),
      { lander },
      queryClient,
      createSession(ctx),
    );

    return {
      lander,
      pageView,
      pageViewData,
      dehydratedState: dehydrate(queryClient),
    };
  } catch (e) {
    return {
      lander,
    };
  }
};

export default ShopFanGearLandingPage;
